import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { autocast } from '../utils';

const features = [
    { name: 'Installation en 1 clic' },
    { name: 'Données hébergées en France' },
    { name: 'Sauvegardes quotidiennes automatiques' },
    { name: 'Nom de domaine personnalisé' },
    { name: 'Sans tracking' },
    { name: 'Open-source', note: 'Code source accessible à tout le monde' },
    { name: 'Mises à jour automatiques' },
    { name: "Nombre d'utilisateurs illimité" },
    { name: 'Écoresponsable', note: 'Serveurs 100% alimentés avec énergie renouvelable' },
    { name: 'Support en ligne' },
    { name: 'Sécurité (SSL, WAF, IPS, Pare-feu)' },
    { name: '99% de disponibilité', note: 'Une garantie que vos applications fonctionnent 99% du temps. Tous les serveurs sont surveillés 24/7' },
];

const DEFAULT_PRICE = 19;
const SPECIAL_REDUCTION = 35;
const REDUCTION = 30;
const MAX_APPS = 8;
const FINAL_PRICE = 99;

const PricingPage = () => {
    const [state, setState] = useState({ applications: 1 });

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        setState({ [name]: autocast(value), event });
    };

    const { applications } = state;
    const price = applications * DEFAULT_PRICE;

    return (
        <div className="pt-28">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Une tarification simple et transparente</h3>
                    <p className="text-center mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">Seulement 19€ par application / mois</p>
                </div>
            </div>
            <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                <div className="relative">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                            <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Abonnement mensuel</h3>
                                <p className="mt-6 text-base text-gray-500">L'installation est offerte, vous payez uniquement au nombre d'application, sans engagement et sans coùt supplémentaire*. Vous pouvez à tout moment transférer vos applications chez un autre hébergeur.</p>
                                <div className="mt-8">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-gray-600">Ce qui est inclus</h4>
                                        <div className="flex-1 border-t-2 border-gray-200" />
                                    </div>
                                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                        {features.map((feature) => (
                                            <li key={feature} className="flex items-start lg:col-span-1">
                                                <div className="flex-shrink-0">
                                                    <CheckCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </div>
                                                { feature.note ? (
                                                    <>
                                                        <p className="ml-3 text-sm text-gray-700 border-b-2 border-dotted cursor-help border-gray-300" data-tip={feature.note}>{feature.name}</p>
                                                        <ReactTooltip type="dark" place="top" effect="solid" />
                                                    </>
                                                ) : (
                                                    <p className="ml-3 text-sm text-gray-700">{feature.name}</p>
                                                ) }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <p className="mt-10 text-xs">*À l'exception de l'application Nextcloud où le prix de l'espace de stockage augmente de 5€ tous les 50Go.</p>
                            </div>
                            <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                <p className="text-lg leading-6 font-medium text-gray-900">
                                    À partir de
                                </p>
                                <p className="mt-3">
                                    { applications >= 3 && <span className="text-center text-2xl line-through">{price}€</span> }
                                    { applications >= 3 ? (
                                        <span className="text-center text-4xl text-5xl font-extrabold text-gray-900 ml-2">
                                            { applications === MAX_APPS ? FINAL_PRICE : Math.round(price - (price * (REDUCTION / 100))) }€
                                        </span>
                                    ) : (
                                        <span className="text-center text-4xl text-5xl font-extrabold text-gray-900 ml-2">{price}€</span>
                                    ) }
                                </p>
                                { applications >= 3 && (<p className="text-xs mb-2">(-{applications === MAX_APPS ? SPECIAL_REDUCTION : REDUCTION}% de réduction)</p>) }
                                <p className="ml-3 font-medium text-gray-500 mt-2">
                                    pour <input name="applications" type="number" value={applications} onChange={handleChange} min="1" max={MAX_APPS} className="border p-0 w-8" /> application / mois
                                </p>
                                <div className="mt-6">
                                    <a href="/app/fr/register" className="button inline-block bg-gray-700 hover:bg-gray-800 text-white py-2 px-8 rounded">
                                        Essayer gratuitement
                                    </a>
                                </div>
                                <div className="mt-4 text-sm">
                                    <a href="#!" className="font-medium text-gray-900">
                                        🔒 Gratuit pendant 7 jours et sans engagement
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingPage;
