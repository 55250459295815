export const checkStatus = (response) => new Promise((resolve, reject) => {
    if (response.status !== 200) {
        return reject(new Error(response.statusText));
    }

    return response.json().then((res) => {
        if (res.errors) {
            reject(res.errors[0]);
        }

        resolve(res);
    });
});

export const isProduction = () => {
    return process.env.NODE_ENV === 'production' && typeof window !== 'undefined';
};

export const autocast = (string) => {
    if (string === null) {
        return '';
    }

    if (!string.length) {
        return '';
    }

    if (!Number.isNaN(Number(string))) {
        return Number(string);
    }

    if ((string === 'true')) {
        return true;
    }

    if ((string === 'false')) {
        return false;
    }

    return string;
};
